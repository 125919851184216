.host {
  /* デフォルトの見た目をリセット */
  appearance: none;
  border: solid 2px transparent;
  outline: none;
  width: 100%;
  min-width: 100px;
  height: 44px;
  padding: 5px 20px;
  border-radius: 4px;
  background: #eff2f5;
  font-size: 14px;
  color: var(--font-black);
}
.host:focus {
  border-color: var(--background-blue);
  box-shadow: 0 0 8px 0 rgba(47, 175, 221, 0.4);
}
.host.invalid {
  border-color: var(--background-red01);
  background: var(--background-red02);
}
.host:disabled {
  opacity: 0.7;
}

.host {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template:
    "header header" auto
    "sidebar main" 1fr /
    auto 1fr;
}

.header {
  grid-area: header;
}

.sidebar {
  grid-area: sidebar;
}

.main {
  grid-area: main;
  overflow: auto;
  background: var(--background-gray03);
}

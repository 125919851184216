.label {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--link-blue);
  font-size: 12px;
  letter-spacing: 0.5px;
  border: var(--link-blue) 1px solid;
  border-radius: 30px;
  padding: 3.5px 10px;
  margin-bottom: 5px;
  margin-right: 5px;
}

/* チェックボックスは非表示 */
.checkbox {
  display: none;
}

/* アクティブなスタイル */
.checkbox:checked + .label {
  background: var(--background-blue);
  color: #fff;
}

.host {
  width: 430px;
  height: fit-content;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 80px 40px;
}
.form > * + * {
  margin-top: 40px;
}

.title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.agreeButton {
  width: 100%;
  height: 55px;
  border-radius: 4px;
  background: #2fafdd;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

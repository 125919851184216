.host {
  display: inline-block;
  position: relative;
  margin-left: -8px;
  margin-right: 8px;
  cursor: pointer;
}

.badge {
  position: absolute;
  top: 3px;
  right: 0px;
}

.badge > * {
  height: 26px;
}

.logo {
  padding: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin: 6px auto 20px;
}

.errors {
  width: 480px;
  margin: auto;
  user-select: all;
}

.errors pre code {
  font-size: 14px;
  color: #666;
}

.host {
  margin: 6px 0 10px 0;
}

.link {
  vertical-align: middle;
}

.deleteButton {
  margin-left: 6px;
}

.disabled {
  pointer-events: none;
  text-decoration: none;
  color: #999999;
}

.host {
  padding: 12.5px 15px;
  position: relative;
}

.host:disabled {
  opacity: 0.3;
}

.badge {
  position: absolute;
  top: 12.5px;
  right: 8px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;

  background: #fff;
  color: #00b9db;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

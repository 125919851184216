.host {
  position: relative;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.toggleButton {
  height: 55px;
  padding: 0 20px 0 12px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;

  display: flex;
  align-items: center;
}
@media (hover: hover) {
  .toggleButton:hover {
    background: var(--background-blue-hover);
  }
}
@media (hover: none) {
  .open .toggleButton {
    background: var(--background-blue-hover);
  }
}
.toggleButton > * {
  flex: 0 0 auto;
}

.username {
  flex: 1 1 auto;
  margin: 0 4px;

  /* 2 行以上は省略表示する */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: max-content;
  z-index: var(--layer-header);

  background: var(--background-blue);
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
}
@media (hover: none) {
  .menu {
    background: var(--background-blue-hover);
  }
}

.item {
  width: 100%;
  padding: 15px 30px 15px 10px;

  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (hover: hover) {
  .item:not(.disabled):hover {
    background: var(--background-blue-hover);
  }
}
.item > * {
  flex: 0 0 auto;
}
.item > * + * {
  margin-left: 10px;
}

.languageSettingModal {
  width: 430px;
  height: fit-content;
}

.languageSettingForm {
  padding: 40px 80px;
}

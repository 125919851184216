.host {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 55px;
  border-radius: 4px;
  padding: 0 20px;
  background: var(--background-blue);
  font-size: 16px;
  color: #fff;
}
.host:disabled {
  background: #ccd9df;
}

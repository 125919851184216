.host {
  display: inline-block;
  position: relative;
}

.badge {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 24px;
  height: 24px;
  border: 0;
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  pointer-events: none;
}

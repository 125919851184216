/**
 * CSS Reset
 * @see https://create-react-app.dev/docs/adding-css-reset
 */
@import-sanitize;

html {
  /* body に指定される min-width によりはみ出る可能性があるため、スクロールできるように */
  overflow: auto;
}

body {
  /* 画面最小幅 (iPad portrait と同じ幅) */
  min-width: var(--screen-min-width);

  margin: 0;
  font-family: system-ui, -apple-system, "Hiragino Sans", "Yu Gothic UI",
    sans-serif;
  color: var(--font-black);
}

html,
body,
#root {
  height: 100%;
}

a {
  color: var(--link-blue);
  text-decoration: underline;
  text-underline-position: under;
}

/* CSS リセット (参考: https://moderncss.dev/css-button-styling-guide/#button) */
button {
  border: none;
  background-color: transparent;
  font-family: inherit;
  padding: 0;
  cursor: pointer;
}

input,
textarea {
  font-family: inherit;
}

/** アプリケーション全体に関連する変数 */
:root {
  /* color */
  --background-blue: #2fafdd;
  --background-blue-hover: #1a9bca;
  --background-darkblue: #003050;
  --background-black: #000;
  --background-white: #fff;
  --background-gray01: #edf1f4;
  --background-gray02: #f7f9fa;
  --background-gray03: #d9e0e4; /* レイアウト指示書 (Sketch) 上には色名の定義がないが共通して使われているので「gray03」と命名 */
  --background-gray04: rgba(0, 0, 0, 0.6);
  --background-gray05: #e0e7ed;
  --background-gray06: #999;
  --background-gray07: #f9fafb;
  --background-gray08: #d8d8d8;
  --background-gray09: #b8b8b8;
  --background-gray10: rgba(255, 255, 255, 0.1);
  --background-gray11: #eff2f5;
  --background-gray12: #dedede;
  --background-gray13: #f0f0f0;
  --background-gray14: #2f3132;
  --background-gray15: #ccd9df;
  --background-gray16: #eee;
  --background-gray17: #e8e8eb;
  --background-gray18: #cacaca;
  --background-gray19: #c8c8c8;
  --background-gray20: #191919;
  --background-gray21: #e4ecef;
  --background-red01: #ec4a4a;
  --background-red02: #fedede;
  --pen-red: #ff1e1e;
  --pen-blue: #1685ea;
  --pen-green: #59d200;
  --pen-brown: #7e3a00;
  --pen-pink: #f81ca6;
  --pen-orange: #ff9b00;
  --pen-gray: #6b6b6b;
  --pen-black: #000000;
  --link-blue: #00b6d7;
  --badge-orange: #ff9300;
  --font-black: #333333;
  --font-black02: #161616;
  --font-gray01: #828282;
  --font-gray02: #d2d2d2;
  --font-gray03: #b8bec2;
  --font-gray04: #898989;
  --font-gray05: #999999;
  /* z-index */
  --layer-layout-frame: 1;
  --layer-header: 2;
  --layer-overlay: 3;
  --layer-modal: 4;
  --layer-offline-modal: 5; /* 通常のモーダルと区別 */
  --layer-lock: 6;
  /* size */
  --screen-min-width: 768px;
}

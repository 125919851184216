.host {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 430px;
  height: 580px;
  margin: auto;
  background: var(--background-white);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background-gray04);
  z-index: var(--layer-offline-modal);
}

.content {
  padding: 40px 58px 50px;
  letter-spacing: 1px;
}

.icon {
  width: fit-content;
  margin: auto;
}

.title {
  margin: 40px 0 20px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
  color: #333;
}

.message {
  margin: 20px auto 0;
  font-size: 18px;
  text-align: center;
  font-weight: 300;
  color: #333;
  line-height: 20px;
}

.confirmMessage {
  margin: 20px auto 40px;
}

.lastLogin {
  letter-spacing: 2px;
  color: #333;
}
.lastLoginDate {
  margin: 0 5px;
}

.button {
  width: 270px;
  margin: 0 15px;
}

.submitBtnWrapper{
  display: flex;
  justify-content: center;
}

.cancelBtnWrapper {
  display: flex;
  justify-content: center;
  margin:20px 0;
}

.cancelButton {
  font-size: 18px;
  font-weight: 500;
  color: var(--link-blue);
}

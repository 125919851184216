.host {
  position: relative;
}

.listBox {
  border: solid 2px transparent;
  outline: none;
  width: 100%;
  min-width: 100px;
  height: 44px;

  padding: 5px 10px;
  border-radius: 4px;
  background: #eff2f5;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
}
.listBox:focus {
  border-color: #2fafdd;
  box-shadow: 0 0 8px 0 rgba(47, 175, 221, 0.4);
}
.listBox.invalid {
  border-color: var(--background-red01);
  background: var(--background-red02);
}

.activeOption {
  display: inline-flex;
  align-items: center;
  height: 30px;
  padding: 5px 10px;
  background: #2fafdd;
  border-radius: 15px;
}
.activeOption[hidden] {
  visibility: hidden;
}
.activeOption + .activeOption {
  margin-left: 10px;
}
.activeOption > * {
  vertical-align: middle;
}

.activeOptionEllipsis {
  display: inline-block;
  padding: 0 10px;
}

.activeOptionText {
  margin-right: 8px;
  font-size: 12px;
  color: #fff;
}

.close {
  cursor: pointer;
}

.items {
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  left: 0;
  z-index: var(--layer-overlay);

  overflow: auto;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
}
.items > *:nth-child(odd) {
  background: var(--background-white);
}
.items > *:nth-child(even) {
  background: var(--background-gray01);
}

.option {
  padding: 17.5px;
  font-size: 16px;
  color: var(--link-blue);
  overflow: hidden;
  text-overflow: ellipsis;
}

.option.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.option.disabled > * {
  opacity: 0.3;
}

.optionText {
  margin-left: 8px;
  vertical-align: middle;
}

.unselectedText {
  display: flex;
  font-size: 14px;
  height: 30px;
  padding: 0 10px;
  align-items: center;
}

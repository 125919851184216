.dateInfo {
  color: var(--background-gray06);
  font-size: 12px;
  display: flex;
  gap: 12px;
  min-width: fit-content;
}

.dateItem {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: var(--background-gray06);
  letter-spacing: 0.6px;
  line-height: 13px;
}

.host {
  height: 55px;
  z-index: var(--layer-header);

  background-color: var(--background-blue);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;

  width: 100%;
}
.left {
  width: calc((100% - 40px) * 0.6);
  padding-left: 16px;

  display: flex;
  align-items: center;
}
.center {
  flex: 0 0 auto;
  width: 40px;
}
.right {
  width: calc((100% - 40px) * 0.4);

  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.showEnvironment .left,
.showEnvironment .right {
  width: calc((100% - 200px) * 0.5);
}
.showEnvironment .center {
  text-align: center;
  width: 200px;
}
/* iPad 縦の場合は左右の比率を変える */
@media (max-width: 1023px) {
  .left,
  .right {
    width: calc((100% - 40px) * 0.5);
  }
}

.goBack {
  margin-right: 16px;
}

.override {
  flex: 1 1 auto;

  font-weight: bold;
  color: #fff;

  /* 2 行以上は省略表示する */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.environment {
  font-weight: bold;
  color: #fff;
}

.separator {
  flex: 0 0 auto;
  width: 15px;
}

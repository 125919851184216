.host {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 75%;
  height: 75%;
  margin: auto;
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background-gray04);
  z-index: var(--layer-modal);
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: contain;
}

.leftButton,
.rightButton {
  position: absolute;
  top: calc(50% - 27.5px);
}
.leftButton {
  left: -82.5px;
}
.rightButton {
  right: -82.5px;
}

.closeButton {
  position: absolute;
  top: -40px;
  right: -40px;
}

.hidden {
  display: none;
}

.clipIcon path {
  stroke: var(--background-blue);
}

.clipIcon.disabled path {
  stroke: var(--background-gray08);
}

.blueHost {
  background: var(--background-blue);
  border-radius: 4px;
  width: 60px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blueHost.disabled {
  background: var(--background-gray08);
}

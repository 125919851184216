.host {
  position: relative;
  width: fit-content;
}

.mentionButton:disabled {
  cursor: pointer;
}

.cursorDefault {
  cursor: default !important;
}

.mentionIcon {
  position: relative;
  width: 30px;
  height: 30px;
}

.mentionButton:not(.cursorDefault) > .mentionIcon path {
  fill: var(--background-blue);
}

.unreadBadge {
  position: absolute;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: var(--badge-orange);
  border: 1px solid var(--background-white);
  top: 3px;
  right: 3px;
}

.mentionPullUp {
  position: absolute;
  box-sizing: border-box;
  z-index: var(--layer-modal);
  bottom: 45px;
  padding: 10px 15px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
  width: 220px;
  max-height: 100px;
  background-color: var(--background-white);
  border-radius: 4px;
}
.mentionPullUp::after {
  content: "";
  position: absolute;
  top: 100%;
  border: 15px solid transparent;
  border-top: 15px solid var(--background-white);
}

.mentionPullUp.center {
  left: 50%;
  transform: translateX(-50%);
}
.mentionPullUp.center::after {
  left: 50%;
  margin-left: -15px;
}

.mentionPullUp.left {
  left: -15px;
}
.mentionPullUp.left::after {
  left: 15px;
}

.mentionPullUp.right {
  right: -15px;
}
.mentionPullUp.right::after {
  right: 15px;
}

.userList {
  width: 190px;
  max-height: 80px;
  overflow-y: auto;
}

.user {
  font-size: 12px;
  line-height: 21px;
  width: 100%;
  color: var(--font-black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

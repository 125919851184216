.host,
.label {
  display: block;
  /* .icon の位置のため */
  position: relative;
}

.icon {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 7px 0;
  pointer-events: none;
}

.label {
  display: block;
}

.text {
  margin: 4px 6px;
  font-weight: bold;
}

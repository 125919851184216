.host {
  display: inline-block;
  position: relative;
}
.host::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.image {
  width: 150px;
  height: 100px;
  object-fit: contain;
}

.deleteButton {
  position: absolute;
  top: 4px;
  right: 4px;
}

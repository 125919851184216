.host {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 90%;
  height: 80%;
  margin: auto;
  background: var(--background-white);
  border-radius: 4px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}
.header {
  flex: 0 0 auto;
}
.children {
  flex: 1 1 auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background-gray04);
  z-index: var(--layer-modal);
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  padding: 0 70px;
  color: #fff;
  background: var(--background-blue);
  position: relative;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 55px;
  padding: 12.5px 20px;
}

.children {
  position: relative;
  overflow: auto;
}

.fit {
  height: fit-content;
}

.list {
  padding: 27px 30px 40px 10px;
}

.noMention {
  align-items: center;
  color: var(--font-black);
  display: flex;
  justify-content: center;
  padding: 40px 30px;
  margin: 40px 0;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  height: fit-content;
}

.noMention span {
  opacity: 0.3;
}

.card > div {
  padding-right: 0;
}
.card:last-child > div {
  border-bottom: 0;
}

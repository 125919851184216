.host {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--layer-lock);

  transition: opacity 0.2s linear;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.host.blue {
  background: var(--background-blue);
}
.host.dark {
  background: var(--background-gray04);
}
.host.disabled {
  opacity: 0;
  pointer-events: none;
}

.spinner {
  animation: rotate 1s linear 0s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.text {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  margin-top: 20px;
}

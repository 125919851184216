.host {
  background-color: var(--background-white);
  padding: 13px 0 0 20px;
  position: relative;
}

.attention {
  position: absolute;
  top: 3px;
  right: 6px;
}

.attention > svg {
  width: 20px;
}

.container {
  padding-right: 16px;
  border-bottom: 1px solid var(--background-gray05);
}

.breadcrumbs {
  margin: 0;
  padding: 0 16px 0 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  color: var(--background-gray06);
}

.breadcrumbs .separator {
  font-size: 12px;
  margin: 0 6px;
  color: var(--background-gray04);
}

.categories {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0 6px 0;
}

.title {
  margin: 0 0 10px 0;
  font-size: 18px;
}

.detail {
  margin: 0 0 10px 0;
}

.button {
  /* ボタンを押しやすくするためレイアウトは変えずにボタンを大きく */
  padding: 10px;
  margin: -10px -10px 0;
  font-size: 16px;
  font-weight: bold;
  color: var(--link-blue);
  text-decoration: underline;
}

.button.detailButton {
  margin-bottom: -10px;
}

.detail[aria-hidden="true"] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.detail[aria-hidden="false"] + .button {
  display: none;
}

.button[aria-hidden="true"] {
  display: none;
}

.detail > span[class="disabled"] {
  color: var(--background-gray06);
}

.meta {
  padding-bottom: 10px;
  color: var(--background-gray06);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.meta .leftItem {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 16px;
  overflow: hidden;
}

.meta .rightItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: min-content;
  white-space: nowrap;
}

.meta .icons {
  display: flex;
  column-gap: 16px;
  margin-right: 16px;
}

.meta .separator {
  min-width: 1px;
  height: 30px;
  margin: 0 6px;
  background-color: var(--background-gray05);
}

.meta .name {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

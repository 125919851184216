.loading {
  position: fixed;
  z-index: var(--layer-lock);
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  width: 270px;
  height: 55px;
  border-radius: 4px;
  background-color: var(--background-black);
  font-size: 16px;
  color: var(--background-white);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.spinner {
  animation: rotate 1s linear 0s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

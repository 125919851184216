.host {
  padding: 40px 30px;
  font-size: 16px;
  height: fit-content;
}
.host > * + * {
  margin-top: 20px;
}

.item {
  padding: 0 0 20px;
  border-bottom: solid 1px #e0e7ed;
}

.date,
.title {
  font-weight: bold;
}

.title {
  margin: 2px auto 6px;
}

.link {
  margin-top: 10px;
  font-weight: bold;
}

.noInformation {
  align-items: center;
  color: #333333;
  display: flex;
  justify-content: center;
  margin: 40px 0;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}

.noInformation span {
  opacity: 0.3;
}
